var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-card",
        { attrs: { "no-body": "" } },
        [
          _c(
            "b-row",
            [
              _c("b-col", { staticClass: "pl-2", attrs: { cols: "12" } }),
              _c(
                "b-col",
                {
                  staticClass: "my-1 pl-2",
                  attrs: { md: "6", sm: "6", xs: "6" },
                },
                [
                  _c(
                    "b-form-group",
                    {
                      staticClass: "mb-0 d-flex align-items-center",
                      attrs: {
                        label: "Search",
                        "label-cols-sm": "2",
                        "label-align-sm": "left",
                        "label-size": "sm",
                        "label-for": "filterInput",
                      },
                    },
                    [
                      _c(
                        "b-input-group",
                        {
                          staticClass: "input-group-merge",
                          attrs: { size: "sm" },
                        },
                        [
                          _c("b-form-input", {
                            attrs: {
                              id: "filterInput",
                              type: "search",
                              placeholder: "Type to Search",
                            },
                            model: {
                              value: _vm.filter,
                              callback: function ($$v) {
                                _vm.filter = $$v
                              },
                              expression: "filter",
                            },
                          }),
                          _c(
                            "b-input-group-append",
                            { attrs: { "is-text": "" } },
                            [
                              _c("feather-icon", {
                                attrs: { icon: "SearchIcon" },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-col",
                {
                  staticClass: "my-1 pr-2 text-right",
                  attrs: { md: "6", sm: "6", xs: "6" },
                },
                [
                  _c(
                    "div",
                    { staticClass: "d-flex align-items-center float-right" },
                    [
                      _c(
                        "b-form-checkbox",
                        {
                          staticClass: "float-right mr-1",
                          model: {
                            value: _vm.showLoginDetails,
                            callback: function ($$v) {
                              _vm.showLoginDetails = $$v
                            },
                            expression: "showLoginDetails",
                          },
                        },
                        [
                          _vm._v(
                            "\n                        Show inactive login details\n                    "
                          ),
                        ]
                      ),
                      _c(
                        "b-button",
                        {
                          staticClass: "float-right",
                          attrs: {
                            type: "button",
                            variant: "primary",
                            size: "sm",
                            pill: "",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.onRowSelected({}, "add")
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n                        + Add\n                    "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { cols: "12" } },
                [
                  _c("b-table", {
                    attrs: {
                      selectable: "",
                      "select-mode": "single",
                      hover: "",
                      responsive: "",
                      "per-page": _vm.perPage,
                      "current-page": _vm.currentPage,
                      items: _vm.showLoginDetails
                        ? _vm.items
                        : _vm.items.filter(function (x) {
                            return x.status == "Active"
                          }),
                      fields:
                        _vm.webLinksToFormField == "practiceId"
                          ? _vm.fieldsWithOverride
                          : _vm.fields,
                      "sort-by": _vm.sortBy,
                      "sort-desc": _vm.sortDesc,
                      "sort-direction": _vm.sortDirection,
                      filter: _vm.filter,
                      "filter-included-fields": _vm.filterOn,
                    },
                    on: {
                      "update:sortBy": function ($event) {
                        _vm.sortBy = $event
                      },
                      "update:sort-by": function ($event) {
                        _vm.sortBy = $event
                      },
                      "update:sortDesc": function ($event) {
                        _vm.sortDesc = $event
                      },
                      "update:sort-desc": function ($event) {
                        _vm.sortDesc = $event
                      },
                      filtered: _vm.onFiltered,
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "cell(nameUrl)",
                        fn: function (data) {
                          return [
                            _vm._v(
                              "\n                        " +
                                _vm._s(data.item.name) +
                                "\n                        "
                            ),
                            _c("br"),
                            _c(
                              "b-link",
                              {
                                staticClass: "primary-text",
                                attrs: { href: data.item.url },
                              },
                              [
                                _vm._v(
                                  _vm._s(data.item.url) +
                                    "\n                            "
                                ),
                                _c("feather-icon", {
                                  staticClass: "ml-50 primary-text",
                                  attrs: {
                                    size: "16",
                                    icon: "ExternalLinkIcon",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                      {
                        key: "cell(source)",
                        fn: function (data) {
                          return [
                            _c("div", [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(
                                    data.item.practiceId
                                      ? "Practice"
                                      : data.item.insuranceId
                                      ? "Company"
                                      : "Insurance Plan"
                                  ) +
                                  "\n                        "
                              ),
                            ]),
                          ]
                        },
                      },
                      {
                        key: "cell(userInfo)",
                        fn: function (data) {
                          return [
                            _c(
                              "div",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.copyToClipboard(data.item.login)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n                            " +
                                    _vm._s(data.item.login) +
                                    "\n                            "
                                ),
                                _c("feather-icon", {
                                  staticClass: "ml-50 primary-text",
                                  attrs: { size: "14", icon: "CopyIcon" },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.copyToClipboard(
                                      data.item.password
                                    )
                                  },
                                },
                              },
                              [
                                _vm._l(9, function (item) {
                                  return _c("span", {
                                    key: item,
                                    staticClass: "dot",
                                  })
                                }),
                                _c("feather-icon", {
                                  staticClass: "ml-50 primary-text",
                                  attrs: { size: "14", icon: "CopyIcon" },
                                }),
                              ],
                              2
                            ),
                          ]
                        },
                      },
                      {
                        key: "cell(comment)",
                        fn: function (data) {
                          return [
                            _vm._v(
                              "\n                        " +
                                _vm._s(data.value) +
                                "\n                    "
                            ),
                          ]
                        },
                      },
                      {
                        key: "cell(status)",
                        fn: function (data) {
                          return [
                            _c(
                              "b-badge",
                              {
                                attrs: {
                                  pill: "",
                                  variant: _vm.status[1][data.value],
                                },
                              },
                              [
                                _vm._v(
                                  "\n                            " +
                                    _vm._s(_vm.status[0][data.value]) +
                                    "\n                        "
                                ),
                              ]
                            ),
                          ]
                        },
                      },
                      {
                        key: "cell(action)",
                        fn: function (data) {
                          return [
                            _c(
                              "div",
                              { staticClass: "d-flex float-right" },
                              [
                                _c(
                                  "b-button",
                                  {
                                    directives: [
                                      {
                                        name: "ripple",
                                        rawName: "v-ripple.400",
                                        value: "rgba(40, 199, 111, 0.15)",
                                        expression:
                                          "'rgba(40, 199, 111, 0.15)'",
                                        modifiers: { 400: true },
                                      },
                                    ],
                                    staticClass: "d-flex px-0",
                                    attrs: {
                                      disabled: _vm.filterByOverridevalue
                                        ? data.item.insuranceId
                                          ? true
                                          : false
                                        : false,
                                      variant: "flat-primary",
                                      size: "sm",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.onRowSelected(
                                          data.item,
                                          "edit"
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c("feather-icon", {
                                      attrs: { icon: "EditIcon", size: "16" },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "b-button",
                                  {
                                    directives: [
                                      {
                                        name: "ripple",
                                        rawName: "v-ripple.400",
                                        value: "rgba(40, 199, 111, 0.15)",
                                        expression:
                                          "'rgba(40, 199, 111, 0.15)'",
                                        modifiers: { 400: true },
                                      },
                                    ],
                                    staticClass: "d-flex px-0 ml-1",
                                    attrs: {
                                      disabled: _vm.filterByOverridevalue
                                        ? data.item.insuranceId
                                          ? true
                                          : false
                                        : false,
                                      variant: "flat-danger",
                                      size: "sm",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.deleteRow(data.item.id)
                                      },
                                    },
                                  },
                                  [
                                    _c("feather-icon", {
                                      attrs: { icon: "Trash2Icon", size: "16" },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _c(
                "b-col",
                { staticClass: "pb-1 pl-2", attrs: { cols: "3" } },
                [
                  _c(
                    "label",
                    { staticClass: "d-inline-block text-sm-left mr-50 pl-2" },
                    [_vm._v("Show")]
                  ),
                  _c("b-form-select", {
                    staticClass: "w-50",
                    attrs: {
                      id: "perPageSelect",
                      size: "sm",
                      options: _vm.pageOptions,
                    },
                    model: {
                      value: _vm.perPage,
                      callback: function ($$v) {
                        _vm.perPage = $$v
                      },
                      expression: "perPage",
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-col",
                { staticClass: "pb-1", attrs: { cols: "9" } },
                [
                  _c("b-pagination", {
                    staticClass: "my-0 pr-2",
                    attrs: {
                      "total-rows": _vm.totalRows,
                      "per-page": _vm.perPage,
                      align: "right",
                      size: "sm",
                    },
                    model: {
                      value: _vm.currentPage,
                      callback: function ($$v) {
                        _vm.currentPage = $$v
                      },
                      expression: "currentPage",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "validation-observer",
        { ref: "simpleRules" },
        [
          _c(
            "b-form",
            [
              _c(
                "b-sidebar",
                {
                  attrs: {
                    id: "sidebar-right",
                    "bg-variant": "white",
                    "no-header": "",
                    right: "",
                    backdrop: "",
                    shadow: "",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "footer",
                      fn: function () {
                        return [
                          _c(
                            "b-container",
                            [
                              _c(
                                "b-row",
                                [
                                  _c("b-col", [
                                    _c(
                                      "div",
                                      { staticClass: "w-100 text-right pb-2" },
                                      [
                                        _vm.mode == "edit"
                                          ? _c(
                                              "b-button",
                                              {
                                                directives: [
                                                  {
                                                    name: "ripple",
                                                    rawName: "v-ripple.400",
                                                    value:
                                                      "rgba(255, 255, 255, 0.15)",
                                                    expression:
                                                      "'rgba(255, 255, 255, 0.15)'",
                                                    modifiers: { 400: true },
                                                  },
                                                ],
                                                attrs: {
                                                  variant: "flat-danger",
                                                  size: "sm",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.deleteRow(
                                                      _vm.selectedRow.id
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass: "align-middle",
                                                  },
                                                  [_vm._v("Delete")]
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                        _c(
                                          "b-button",
                                          {
                                            directives: [
                                              {
                                                name: "ripple",
                                                rawName: "v-ripple.400",
                                                value:
                                                  "rgba(255, 255, 255, 0.15)",
                                                expression:
                                                  "'rgba(255, 255, 255, 0.15)'",
                                                modifiers: { 400: true },
                                              },
                                            ],
                                            staticClass: "ml-1 mr-1",
                                            attrs: {
                                              variant: "outline-primary",
                                              size: "sm",
                                            },
                                            on: {
                                              click: function ($event) {
                                                _vm.sidebar = false
                                                _vm.selectedRow = {}
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                                        Cancel\n                                    "
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "b-button",
                                          {
                                            directives: [
                                              {
                                                name: "ripple",
                                                rawName: "v-ripple.400",
                                                value:
                                                  "rgba(255, 255, 255, 0.15)",
                                                expression:
                                                  "'rgba(255, 255, 255, 0.15)'",
                                                modifiers: { 400: true },
                                              },
                                            ],
                                            attrs: {
                                              variant: "primary",
                                              size: "sm",
                                              type: "submit",
                                            },
                                            on: {
                                              click: function ($event) {
                                                $event.preventDefault()
                                                return _vm.validationForm.apply(
                                                  null,
                                                  arguments
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c("feather-icon", {
                                              staticClass: "mr-50",
                                              attrs: { icon: "CheckIcon" },
                                            }),
                                            _c(
                                              "span",
                                              { staticClass: "align-middle" },
                                              [_vm._v("Done")]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                  model: {
                    value: _vm.sidebar,
                    callback: function ($$v) {
                      _vm.sidebar = $$v
                    },
                    expression: "sidebar",
                  },
                },
                [
                  _c(
                    "b-container",
                    { staticClass: "px-2 mt-1" },
                    [
                      _c(
                        "b-row",
                        [
                          _c(
                            "b-col",
                            [
                              _vm.mode == "edit"
                                ? _c("h4", [_vm._v("Edit Login Details")])
                                : _c("h4", [_vm._v("Add Login Details")]),
                              _vm.mode == "edit"
                                ? _c(
                                    "b-form-group",
                                    {
                                      staticClass: "mt-2",
                                      attrs: { label: "Login details active?" },
                                    },
                                    [
                                      _c("validation-provider", {
                                        staticClass: "demo-inline-spacing pt-0",
                                        attrs: {
                                          name: "ProcedureReferral",
                                          rules: "required",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function (ref) {
                                                var errors = ref.errors
                                                return [
                                                  _c(
                                                    "b-form-radio",
                                                    {
                                                      staticClass: "pt-0 mt-0",
                                                      attrs: {
                                                        name: "ProcedureReferral",
                                                        value: "Active",
                                                        required: "",
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.selectedRow
                                                            .status,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.selectedRow,
                                                            "status",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "selectedRow.status",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                                        Yes\n                                    "
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "b-form-radio",
                                                    {
                                                      staticClass: "pt-0 mt-0",
                                                      attrs: {
                                                        name: "ProcedureReferral",
                                                        value: "Inactive",
                                                        required: "",
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.selectedRow
                                                            .status,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.selectedRow,
                                                            "status",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "selectedRow.status",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                                        No\n                                    "
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "small",
                                                    {
                                                      staticClass:
                                                        "text-danger",
                                                    },
                                                    [_vm._v(_vm._s(errors[0]))]
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          false,
                                          1657235083
                                        ),
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.webLinksToFormField == "practiceId"
                                ? _c(
                                    "b-form-group",
                                    {
                                      staticClass: "mt-2",
                                      attrs: { label: "Insurance" },
                                    },
                                    [
                                      _c("validation-provider", {
                                        attrs: { name: "Insurance" },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function (ref) {
                                                var errors = ref.errors
                                                return [
                                                  _c("infinit-select", {
                                                    attrs: {
                                                      repo: _vm.insurancesRepo,
                                                      "include-ids": [
                                                        _vm.selectedRow
                                                          .insuranceId,
                                                      ],
                                                      disabled:
                                                        _vm.selectedRow
                                                          .status == "Inactive",
                                                      label: "name",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.selectedRow
                                                          .insuranceId,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.selectedRow,
                                                          "insuranceId",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "selectedRow.insuranceId",
                                                    },
                                                  }),
                                                  _c(
                                                    "small",
                                                    {
                                                      staticClass:
                                                        "text-danger",
                                                    },
                                                    [_vm._v(_vm._s(errors[0]))]
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          false,
                                          3576734796
                                        ),
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c(
                                "b-form-group",
                                {
                                  staticClass: "mt-2",
                                  attrs: { label: "Name" },
                                },
                                [
                                  _c("validation-provider", {
                                    attrs: { name: "Name", rules: "required" },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "default",
                                        fn: function (ref) {
                                          var errors = ref.errors
                                          return [
                                            _c("b-form-input", {
                                              attrs: {
                                                disabled:
                                                  _vm.selectedRow.status ==
                                                  "Inactive",
                                                required: "",
                                              },
                                              model: {
                                                value: _vm.selectedRow.name,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.selectedRow,
                                                    "name",
                                                    $$v
                                                  )
                                                },
                                                expression: "selectedRow.name",
                                              },
                                            }),
                                            _c(
                                              "small",
                                              { staticClass: "text-danger" },
                                              [_vm._v(_vm._s(errors[0]))]
                                            ),
                                          ]
                                        },
                                      },
                                    ]),
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "b-form-group",
                                {
                                  staticClass: "mt-2",
                                  attrs: { label: "URL" },
                                },
                                [
                                  _c("validation-provider", {
                                    attrs: { name: "URL", rules: "required" },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "default",
                                        fn: function (ref) {
                                          var errors = ref.errors
                                          return [
                                            _c("b-form-input", {
                                              attrs: {
                                                disabled:
                                                  _vm.selectedRow.status ==
                                                  "Inactive",
                                                required: "",
                                              },
                                              model: {
                                                value: _vm.selectedRow.url,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.selectedRow,
                                                    "url",
                                                    $$v
                                                  )
                                                },
                                                expression: "selectedRow.url",
                                              },
                                            }),
                                            _c(
                                              "small",
                                              { staticClass: "text-danger" },
                                              [_vm._v(_vm._s(errors[0]))]
                                            ),
                                          ]
                                        },
                                      },
                                    ]),
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "b-form-group",
                                {
                                  staticClass: "mt-2",
                                  attrs: { label: "Login Username/Email" },
                                },
                                [
                                  _c("validation-provider", {
                                    attrs: {
                                      name: "Login Username/Email",
                                      rules: "required",
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "default",
                                        fn: function (ref) {
                                          var errors = ref.errors
                                          return [
                                            _c("b-form-input", {
                                              attrs: {
                                                disabled:
                                                  _vm.selectedRow.status ==
                                                  "Inactive",
                                                required: "",
                                              },
                                              model: {
                                                value: _vm.selectedRow.login,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.selectedRow,
                                                    "login",
                                                    $$v
                                                  )
                                                },
                                                expression: "selectedRow.login",
                                              },
                                            }),
                                            _c(
                                              "small",
                                              { staticClass: "text-danger" },
                                              [_vm._v(_vm._s(errors[0]))]
                                            ),
                                          ]
                                        },
                                      },
                                    ]),
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "b-form-group",
                                {
                                  staticClass: "mt-2",
                                  attrs: { label: "Password" },
                                },
                                [
                                  _c("validation-provider", {
                                    attrs: {
                                      name: "Password",
                                      rules: "required",
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "default",
                                        fn: function (ref) {
                                          var errors = ref.errors
                                          return [
                                            _c("b-form-input", {
                                              attrs: {
                                                disabled:
                                                  _vm.selectedRow.status ==
                                                  "Inactive",
                                                type: "password",
                                                required: "",
                                              },
                                              model: {
                                                value: _vm.selectedRow.password,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.selectedRow,
                                                    "password",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "selectedRow.password",
                                              },
                                            }),
                                            _c(
                                              "small",
                                              { staticClass: "text-danger" },
                                              [_vm._v(_vm._s(errors[0]))]
                                            ),
                                          ]
                                        },
                                      },
                                    ]),
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "b-form-group",
                                {
                                  staticClass: "mt-2",
                                  attrs: { label: "Comment" },
                                },
                                [
                                  _c("validation-provider", {
                                    attrs: {
                                      name: "Comment",
                                      rules: "required",
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "default",
                                        fn: function (ref) {
                                          var errors = ref.errors
                                          return [
                                            _c("b-form-input", {
                                              attrs: {
                                                disabled:
                                                  _vm.selectedRow.status ==
                                                  "Inactive",
                                                required: "",
                                              },
                                              model: {
                                                value: _vm.selectedRow.comment,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.selectedRow,
                                                    "comment",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "selectedRow.comment",
                                              },
                                            }),
                                            _c(
                                              "small",
                                              { staticClass: "text-danger" },
                                              [_vm._v(_vm._s(errors[0]))]
                                            ),
                                          ]
                                        },
                                      },
                                    ]),
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }